<template>

    <ReportTotals :active-project-default='activeProjectDefault' :is-project-report='true' :report='report' />

    <div class='grid'>
        <div class='col-12'>
                <Toast />
                <Toolbar class='mb-2 mt-3'>

                    <template v-slot:start>
                        <Calendar id='selectedMonth' class="mr-2" view="month" dateFormat="MM - yy" :showIcon='true' :showButtonBar='true' v-model='selectedDate' @date-select='selectedDateChanged'></Calendar>
                        <Button label='Filtrele' icon='pi pi-filter' class='p-button-primary' @click='refreshFoodPaymentLists' />
                    </template>

                    <template v-slot:end>
                        <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                            <h3>Hakediş Raporu</h3>
                            <p class="text-lg">
                                Ay seçimi sonrası filtrele butonuna tıklayın. O ay içerisinde tüm projelerde gerçekleşmiş hakedişleri görüntüleyebilirsiniz.
                             <br>
                            </p>
                        </Sidebar>

                        <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success mr-2' @click='exportExcel'></Button>
                        <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger' />
                    </template>
                </Toolbar>


                <DataTable ref='dt' :value='foodPaymentList' dataKey='id' :paginator='true' :rows='100' :filters='filters' :loading='loadingIndicator' paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown' :rowsPerPageOptions='[5,10,25,50,100,200,300]' currentPageReportTemplate='{first} Sayfada {last} Kayıt Gösteriliyor. Toplam Kayıt: {totalRecords}' responsiveLayout='scroll' :globalFilterFields="['project.name']" showGridlines stripedRows>

                    <template #header>
                        <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                            <h5 class='mb-3 mt-3'>Hakediş Raporu</h5>
                            <span class='block mt-2 md:mt-0 p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama...' />
                            </span>
                        </div>
                    </template>

                    <template #loading>
                        Hakedişler Hesaplanıyor. Lütfen Bekleyin.
                    </template>

                    <template #empty>
                        Hakediş Bulunamadı.
                    </template>

                    <Column field='project.name' header='Proje' :sortable='true' style='width:14rem;'>
                    </Column>

                    <Column field='paymentPeriod' header='Dönem' :sortable='false' style='width:6rem;' bodyClass='text-center'>
                        <template #body='slotProps'>
                           {{slotProps.data.paymentPeriod}}
                        </template>
                    </Column>
                    
                    <Column field='breakfastActualValue' header='Sabah Servis' :sortable='true' style='width:7rem' bodyClass='text-center'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sabah Servis</span>
                            <span :class='actualQtyClass(slotProps.data.breakfastActualValue)'>{{ formatQty(slotProps.data.breakfastActualValue) }}</span>
                        </template>
                    </Column>

                    <Column field='breakfastCalculatedValue' header='Sabah Satış' :sortable='true' style='width:7rem' bodyClass='text-right'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sabah Satış</span>
                            <span :class='calculatedQtyClass(slotProps.data.breakfastCalculatedValue)'>{{ formatQty(slotProps.data.breakfastCalculatedValue) }}</span>

                            <i class='ml-2' v-if='slotProps.data.breakfastCalculatedValue < slotProps.data.breakfastActualValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='slotProps.data.breakfastCalculatedValue > slotProps.data.breakfastActualValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='slotProps.data.breakfastCalculatedValue == slotProps.data.breakfastActualValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='lunchActualValue' header='Öğlen Servis' :sortable='true' style='width:7rem' bodyClass='text-center'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Öğlen Servis</span>
                            <span :class='actualQtyClass(slotProps.data.lunchActualValue)'>{{ formatQty(slotProps.data.lunchActualValue) }}</span>
                         </template>
                    </Column>

                    <Column field='lunchCalculatedValue' header='Öğlen Satış' :sortable='true' style='width:7rem' bodyClass='text-right'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Öğlen Satış</span>
                            <span :class='calculatedQtyClass(slotProps.data.lunchCalculatedValue)'>{{ formatQty(slotProps.data.lunchCalculatedValue) }}</span>

                            <i class='ml-2' v-if='slotProps.data.lunchCalculatedValue < slotProps.data.lunchActualValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='slotProps.data.lunchCalculatedValue > slotProps.data.lunchActualValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='slotProps.data.lunchCalculatedValue == slotProps.data.lunchActualValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='dinnerActualValue' header='Akşam Servis' :sortable='true' style='width:7rem' bodyClass='text-center'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Akşam Servis</span>
                            <span :class='actualQtyClass(slotProps.data.dinnerActualValue)'>{{ formatQty(slotProps.data.dinnerActualValue) }}</span>
                        </template>
                    </Column>

                    <Column field='dinnerCalculatedValue' header='Akşam Satış' :sortable='true' style='width:7rem' bodyClass='text-right'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Akşam Satış</span>
                            <span :class='calculatedQtyClass(slotProps.data.dinnerCalculatedValue)'>{{ formatQty(slotProps.data.dinnerCalculatedValue) }}</span>

                            <i class='ml-2' v-if='slotProps.data.dinnerCalculatedValue < slotProps.data.dinnerActualValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='slotProps.data.dinnerCalculatedValue > slotProps.data.dinnerActualValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='slotProps.data.dinnerCalculatedValue == slotProps.data.dinnerActualValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='nightMealActualValue' header='Gece Servis' :sortable='true' style='width:7rem' bodyClass='text-center'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Gece Servis</span>
                            <span :class='actualQtyClass(slotProps.data.nightMealActualValue)'>{{ formatQty(slotProps.data.nightMealActualValue) }}</span>
                        </template>
                    </Column>

                    <Column field='nightMealCalculatedValue' header='Gece Satış' :sortable='true' style='width:7rem' bodyClass='text-right'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Gece Satış</span>
                            <span :class='calculatedQtyClass(slotProps.data.nightMealCalculatedValue)'>{{ formatQty(slotProps.data.nightMealCalculatedValue) }}</span>

                            <i class='ml-2' v-if='slotProps.data.nightCalculatedValue < slotProps.data.nightActualValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='slotProps.data.nightCalculatedValue > slotProps.data.nightActualValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='slotProps.data.nightCalculatedValue == slotProps.data.nightActualValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='snackActualValue' header='Kumanya Servis' :sortable='true' style='width:7rem' bodyClass='text-center'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Kumanya Servis</span>
                            <span :class='actualQtyClass(slotProps.data.snackActualValue)'>{{ formatQty(slotProps.data.snackActualValue) }}</span>
                        </template>
                    </Column>

                    <Column field='snackCalculatedValue' header='Kumanya Satış' :sortable='true' style='width:7rem' bodyClass='text-right'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Kumanya Satış</span>
                            <span :class='calculatedQtyClass(slotProps.data.snackCalculatedValue)'>{{ formatQty(slotProps.data.snackCalculatedValue) }}</span>

                            <i class='ml-2' v-if='slotProps.data.snackCalculatedValue < slotProps.data.snackActualValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='slotProps.data.snackCalculatedValue > slotProps.data.snackActualValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='slotProps.data.snackCalculatedValue == slotProps.data.snackActualValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='iftarActualValue' header='İftar Servis' :sortable='true' style='width:7rem' bodyClass='text-center'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>İftar Servis</span>
                            <span :class='actualQtyClass(slotProps.data.iftarActualValue)'>{{ formatQty(slotProps.data.iftarActualValue) }}</span>
                        </template>
                    </Column>

                    <Column field='iftarCalculatedValue' header='İftar Satış' :sortable='true' style='width:7rem' bodyClass='text-right'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>İftar Satış</span>
                            <span :class='calculatedQtyClass(slotProps.data.iftarCalculatedValue)'>{{ formatQty(slotProps.data.iftarCalculatedValue) }}</span>

                            <i class='ml-2' v-if='slotProps.data.iftarCalculatedValue < slotProps.data.iftarActualValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='slotProps.data.iftarCalculatedValue > slotProps.data.iftarActualValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='slotProps.data.iftarCalculatedValue == slotProps.data.iftarActualValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='sahurActualValue' header='Sahur Servis' :sortable='true' style='width:7rem' bodyClass='text-center'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sahur Servis</span>
                            <span :class='actualQtyClass(slotProps.data.sahurActualValue)'>{{ formatQty(slotProps.data.sahurActualValue) }}</span>
                        </template>
                    </Column>

                    <Column field='sahurCalculatedValue' header='Sahur Satış' :sortable='true' style='width:7rem' bodyClass='text-right'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sahur Satış</span>
                            <span :class='calculatedQtyClass(slotProps.data.sahurCalculatedValue)'>{{ formatQty(slotProps.data.sahurCalculatedValue) }}</span>

                            <i class='ml-2' v-if='slotProps.data.sahurCalculatedValue < slotProps.data.sahurActualValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='slotProps.data.sahurCalculatedValue > slotProps.data.sahurActualValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='slotProps.data.sahurCalculatedValue == slotProps.data.sahurActualValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='paymentTotal' header='Toplam' :sortable='true' style='width:12rem;' bodyClass='text-right'>
                        <template #body='slotProps'>
                            <span class="paymentTotal">{{ formatPrice(slotProps.data.paymentTotal) }}</span> <span class='font-light ml-1'>&#8378;</span>
                        </template>
                    </Column>

                </DataTable>

        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import FoodPaymentService from '../../services/foodPaymentService';
import store from '@/store';
import moment from 'moment';
import ProjectDefaultService from '../../services/projectDefaultService';
import ReportTotals from '../../components/shared/ReportTotals';
import { checkUserRole, getProjects } from '../common/commonConstantFunctions';

export default {
    components: { ReportTotals },
    data() {
        return {
            filters: null,
            selectedProject:-1,
            projectList:[],
            selectedDate:null,
            activeProjectDefault:{
                isBreakfastVisible: true,
                isLunchVisible: true,
                isDinnerVisible: true,
                isSnackVisible: true,
                isIftarVisible: true,
                isSahurVisible: true,
                isNightMealVisible: true,
                isBreakfastOrderVisible: false,
                isLunchOrderVisible: false,
                isDinnerOrderVisible: false,
                isIftarOrderVisible: false,
                isSnackOrderVisible: false,
                isSahurOrderVisible:false,
                isNightMealOrderVisible: false,
            },
            loadingIndicator: false,
            startDate: null,
            endDate:null,
            projectId: 0,
            userId: 0,
            report:{
                breakfastTotal: 0,
                breakfastExpected: 0,
                breakfastActual: 0,
                breakfastCalculated:0,
                lunchTotal: 0,
                lunchExpected: 0,
                lunchActual: 0,
                lunchCalculated:0,
                dinnerActual: 0,
                dinnerTotal: 0,
                dinnerExpected: 0,
                dinnerCalculated:0,
                snackActual: 0,
                snackTotal: 0,
                snackExpected: 0,
                snackCalculated:0,
                sahurActual: 0,
                sahurTotal: 0,
                sahurExpected: 0,
                sahurCalculated:0,
                iftarActual: 0,
                iftarTotal: 0,
                iftarExpected: 0,
                iftarCalculated:0,
                nightMealExpected:0,
                nightMealActual:0,
                nightMealTotal:0,
                nightMealCalculated:0,
                paymentTotal: 0,
                totalExpected: 0,
                totalActual: 0,
                totalCalculated: 0,
                
            },
            foodPaymentList: [],
            submitted: false,
            visibleLeft: false,
        };
    },
    foodPaymentService: null,
    projectDefaultService: null,
    created() {
        checkUserRole(this, "Admin");
        this.projectList = getProjects();
        this.selectedDate = moment().toDate();
        this.projectId = store.getters.getActiveProject.id;
        this.userId = store.getters.getUserId;
        this.foodPaymentService = new FoodPaymentService();
        this.projectDefaultService = new ProjectDefaultService();
        this.initFilters();
    },

    async mounted() {

    },
    methods: {
        async getFoodPaymentReport() {
            let startDate = moment(this.selectedDate).startOf('month').valueOf();
            let endDate = moment(this.selectedDate).endOf('month').valueOf();

            let getReportResponse = await this.foodPaymentService.getProjectFoodReportsForDailyReport(this.selectedProject,startDate, endDate);
            if (getReportResponse.isSuccess) {
                this.report = getReportResponse.data;
                this.loadingIndicator = false;
            }
        },
        async projectChange(event) {
            this.selectedProject = event.value;
            await this.refreshFoodPaymentLists();
        },
        async exportExcel() {
            this.loadingIndicator = true;
            let startDate = moment(this.selectedDate).startOf('month').valueOf();
            let endDate = moment(this.selectedDate).endOf('month').valueOf();
            let excelExports = await this.foodPaymentService.getProjectFoodPaymentsForMonthlyReportExcel(this.selectedProject, startDate, endDate );
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Projeler Satış Raporu - " + moment(this.selectedDate).startOf('month').toDate()  + ".xlsx";
            link.click();
            this.loadingIndicator = false;
        },


        async refreshFoodPaymentLists() {
            this.loadingIndicator = true;
            let startDate = moment(this.selectedDate).startOf('month').valueOf();
            let endDate = moment(this.selectedDate).endOf('month').valueOf();

            let getResponse = await this.foodPaymentService.getProjectFoodPaymentsForMonthlyReport(this.selectedProject, startDate, endDate);
            if (getResponse.isSuccess) {
                this.foodPaymentList = getResponse.data;
                this.loadingIndicator = false;
            }
            await this.getFoodPaymentReport();
        },

        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        formatDate(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY - dddd');
        },
        formatDateYA(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('YYYY - MM');
        },
        editFoodPayment(data) {
            let newDate = moment(data.paymentDate).valueOf();
            this.$router.push({ name: 'calculateFoodPaymentWithPaymentDate', params: { paymentDate: newDate } });
        },

        formatQty(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        actualQtyClass(data) {
            return [
                {
                    'zeroQty': data === 0,
                    'validQty': data > 0,
                },
            ];
        },

        calculatedQtyClass(data) {
            return [
                {
                    'zeroQty2': data === 0,
                    'validQty2': data > 0,
                },
            ];
        },
        
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }


    },
};
</script>

<style scoped lang='scss'>
.zeroQty {
    color: #FF5252;
    font-size: 15px;
}

.validQty {
    color: #495057;
    font-size: 15px;
}

.zeroQty2 {
    color: #FF5252;
    font-weight: bold;
    font-size: 15px;
}

.validQty2 {
    color: #495057;
    font-weight: bold;
    font-size: 16px;
}


.paymentTotal {
    font-weight: bold;
    color: #16a34a;
    font-size: 16px;
}

.validQtyDefault {
    font-weight: bold;
    font-size: 15px;
}

</style>



